import { cn } from "@/utils/cn"
import Image from "next/image"
import React from "react"

interface LoaderProps {
  kind?: "simple" | "full"
  className?: string
}

export const Loader = ({
  kind = "full",
  className,
}: LoaderProps): JSX.Element => {
  if (kind === "simple")
    return (
      <div
        className={cn(
          `
            relative h-6 w-6
            dark:invert
          `,
          className
        )}
      >
        <Image src={"/assets/loader32px.svg"} priority fill alt="Loading" />
      </div>
    )
  else
    return (
      <div
        className={cn(
          `
            relative h-16 w-16
            dark:invert
          `,
          className
        )}
      >
        <Image src={"/assets/loader96px.svg"} priority fill alt="Loading" />
      </div>
    )
}
