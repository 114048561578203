import React, { forwardRef, ButtonHTMLAttributes, useState } from "react"
import { cn } from "@/utils/cn"
import { Loader } from "./loader"
import Countdown from "react-countdown"

type ButtonKind = "primary" | "secondary" | "tertiarty" | "ghost" | "invert"

const buttonKindColors = {
  primary: `bg-sr-text-primary text-sr-bg-primary hover:text-sr-text-tertiary active:text-sr-text-secondary disabled:border-sr-border-tertiary disabled:text-sr-text-secondary disabled:bg-sr-500 disabled:dark:bg-sr-700 disabled:dim:bg-sr-700`,
  secondary: `bg-sr-bg-primary border border-sr-border-primary hover:border-sr-border-secondary active:text-sr-text-secondary disabled:border-sr-border-tertiary disabled:bg-sr-bg-tertiary`,
  tertiarty: `bg-sr-bg-primary border border-sr-border-secondary hover:border-sr-border-primary active:text-sr-text-secondary disabled:border-sr-border-tertiary disabled:bg-sr-bg-tertiary`,
  ghost: `bg-transparent text-sr-text-primary`,
  invert: `bg-white dark:bg-black dim:bg-white dim:text-black disabled:border-sr-border-tertiary disabled:border-sr-border-tertiary disabled:dim:text-sr-text-secondary disabled:text-sr-text-secondary disabled:bg-sr-500 disabled:dark:bg-sr-700 disabled:dim:bg-sr-700`,
}

const buttonKindCountdownColors = {
  primary: `bg-white dark:bg-black`,
  secondary: `bg-black dark:bg-white`,
  tertiarty: `bg-black dark:bg-white`,
  ghost: `bg-black dark:bg-white`,
  invert: `bg-black dark:bg-white`,
}

const buttonSizeSpec = {
  sm: `text-sm h-[28px] px-5`,
  md: `text-base h-[32px] px-6`,
  lg: `text-base h-[40px] px-6`,
  full: `text-base h-[32px] w-full`,
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  kind?: ButtonKind
  size?: "sm" | "md" | "lg" | "full"
  isIcon?: boolean
  className?: string
  loading?: boolean | string
  uppercase?: boolean
  countdown?: string | number | Date | null
  onCountdownComplete?: () => void
  showChildOnHover?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      kind = "primary",
      size = "md",
      className,
      isIcon,
      loading,
      uppercase,
      countdown,
      showChildOnHover,
      onCountdownComplete,
      ...props
    },
    ref
  ): JSX.Element => {
    const buttonStyle = buttonKindColors[kind]
    const buttonSize = buttonSizeSpec[size]
    const buttonCountdown = buttonKindCountdownColors[kind]
    const [isHovered, setIsHovered] = useState(false)
    return (
      <button
        ref={ref}
        className={cn(
          `flex items-center justify-center gap-x-3 rounded-md border-0 duration-300`,
          loading ? "cursor-not-allowed text-opacity-50" : "",
          buttonStyle,
          buttonSize,
          isIcon ? "size-8 p-0" : "",
          className,
          uppercase && "uppercase"
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...props}
      >
        {countdown && !showChildOnHover ? (
          <div className="flex items-center justify-center gap-x-3">
            <span className="relative flex size-3 items-center justify-center">
              <span
                className={cn(
                  "absolute inline-flex h-full w-full animate-ping rounded-full opacity-60",
                  buttonCountdown
                )}
              ></span>
              <span
                className={cn(
                  "relative inline-flex size-2 rounded-full",
                  buttonCountdown
                )}
              ></span>
            </span>
            <Countdown
              date={countdown}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <span>
                    {days > 0 && `${days}d`}{" "}
                    {(hours > 0 || days > 0) && `${hours}h`}{" "}
                    {minutes > 0 && `${minutes}m`}{" "}
                    {seconds > 0 && days === 0 && `${seconds}s`}
                  </span>
                )
              }}
              onComplete={onCountdownComplete}
            />
          </div>
        ) : null}
        {countdown && showChildOnHover && !isHovered ? (
          <div className="flex items-center justify-center gap-x-3">
            <span className="relative flex size-3 items-center justify-center">
              <span
                className={cn(
                  "absolute inline-flex h-full w-full animate-ping rounded-full opacity-60",
                  buttonCountdown
                )}
              ></span>
              <span
                className={cn(
                  "relative inline-flex size-2 rounded-full",
                  buttonCountdown
                )}
              ></span>
            </span>
            <Countdown
              date={countdown}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <span>
                    {days > 0 && `${days}d`} {hours > 0 && `${hours}h`}{" "}
                    {minutes > 0 && `${minutes}m`}{" "}
                    {seconds > 0 && `${seconds}s`}
                  </span>
                )
              }}
              onComplete={onCountdownComplete}
            />
          </div>
        ) : countdown && showChildOnHover && isHovered ? (
          children
        ) : null}
        {loading && (
          <Loader
            className={cn(
              kind === "primary"
                ? `
                  invert
                  dark:invert-0
                `
                : "",
              "h-3 w-3"
            )}
            kind="simple"
          />
        )}
        {countdown ? null : typeof loading === "string" ? loading : children}
      </button>
    )
  }
)
