"use client"

import { Button } from "@/ui/button"
import { useEffect } from "react"
import { useToggle } from "react-use"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}): JSX.Element {
  const [shouldShowError, toggleShouldShowError] = useToggle(false)
  const isTokenNotFound = error.message === "Token not found"
  useEffect(() => {
    const dev =
      window.location.hostname === "localhost" ||
      window.location.hostname.includes("dev")
    if (dev) {
      toggleShouldShowError(true)
    }
  }, [error])

  return (
    <div className="flex flex-1 flex-col items-center justify-center p-32 text-center">
      <div className="relative z-10 rounded-md border border-sr-border-primary bg-sr-bg-primary p-6">
        <h2 className="text-[128px] font-bold leading-[1]">{"Ooops!"}</h2>
        <h3 className="text-2xl font-thin">
          {shouldShowError || isTokenNotFound
            ? error.message
            : "Something went wrong"}
        </h3>
        <Button className="mt-5 w-full" kind="primary" onClick={() => reset()}>
          Try again
        </Button>
      </div>
      <div className="dvd">
        <b></b>
      </div>
    </div>
  )
}
